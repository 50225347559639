import ApplicationController from "./application_controller";

export default class extends ApplicationController {

    static targets = [ "input", "output" ]

    connect() {
        console.log("rangeslider found! hooray");
    }

    initialize() {
        this.update()
    }

    update() {
        var number = this.inputTarget.value
        this.outputTarget.textContent = this.inputTarget.value

    }

    submit(event) {
        var count = this.inputTarget.value
    }
}