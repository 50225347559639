import ApplicationController from "./application_controller";
// disabled
//import flowy from "flowy-engine/flowy-engine.js"

export default class extends ApplicationController {

  connect() {
    console.log('flowy controller connected')
    var spacing_x = 100
    var spacing_y = 100
    // Initialize Flowy
    flowy(this.element, onGrab, onRelease, onSnap, onRearrange, spacing_x, spacing_y)
    function onGrab(block) {
      // When the user grabs a block
    }
    function onRelease() {
      // When the user releases a block
    }
    function onSnap(block, first, parent) {
      // When a block snaps with another one
    }
    function onRearrange(block, parent) {
      // When a block is rearranged
    }

  }

}
