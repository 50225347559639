import ApplicationController from "./application_controller";

//import TomSelect from 'tom-select/dist/esm/tom-select'
import TomSelect from 'tom-select'
import 'tom-select/dist/esm/plugins/remove_button/plugin'
import 'tom-select/dist/esm/plugins/dropdown_input/plugin'
import 'tom-select/dist/css/tom-select.css'
import '../stylesheets/tom-select.scss'

//const selectInput = document.getElementById('applicant_tag_list')

export default class extends ApplicationController {
    static targets = [ "input" ]


    connect() {
        //this.inputTarget.classList.add('hidden');

        const allowCreate = this.inputTarget.dataset.tomselectCreate === 'true'; // Check if the dataset attribute is set to 'true'

        // Fetch options from the select element
        const options = Array.from(this.inputTarget.options).map(option => {
            return {
                value: option.value,
                text: option.text,
                subtext: option.dataset.subtext // Fetch the data-subtext attribute
            };
        });

        let plugins = {};
        if (allowCreate) {
            plugins.remove_button = {
                title: 'Entfernen'
            };
        }


        this.tomselect = new TomSelect(this.inputTarget, {
            plugins: plugins,
            persist: false,
            create: allowCreate, // Set create based on the condition
            placeholder: "Bitte auswählen",
            options: options,
            render: {
                no_results: function (data, escape) {
                    return '<div class="no-results">Keine Ergebnisse für "' + escape(data.input) + '". Entfernen mit der ␛-Taste</div>';
                },
                option_create: function(data, escape) {
                    if (allowCreate) {
                        return '<div class="create"><strong>' + escape(data.input) + '</strong> hinzufügen &hellip;</div>';
                    } else {
                        return ''; // Return an empty string to hide the "Add" option
                    }
                },
                option: function(data, escape) {
                    let subtext = data.subtext ? '<br/><span class="subtext text-xs text-gray-400 truncate">' + escape(data.subtext) + '</span>' : '';
                    return '<div class="option">' +
                        '<span class="title text-sm">' + escape(data.text) + '</span>' +
                        subtext +
                        '</div>';
                },
                item: function(data, escape) {
                    return '<div class="item text-sm">' + escape(data.text) + '</div>';
                }
            },
            onInitialize: () => {
                this.inputTarget.classList.remove('hidden');
            }
        });
    }




    disconnect() {
        this.tomselect.destroy(this.inputTarget)
    }
}