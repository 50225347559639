import ApplicationController from "./application_controller";

export default class extends ApplicationController {
    static targets = ["input"]

    connect() {

    }

    checkFileType() {
        const file = this.inputTarget.files[0]
        if (file && file.type !== "application/pdf") {
            alert("Bitte laden Sie eine PDF-Datei hoch (Please upload a PDF file.)")
            this.inputTarget.value = ""  // Clear the input
        }
    }
}
